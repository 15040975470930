import React from "react";
import Nav from "../Nav";
import Headline from "./Headline";
import HowItWorks from "./HowItWorks";
import GameRules from "./GameRules";
import Footer from "../Footer";
import MeetBeasts from "./MeetBeasts";
import WatchTheVideo from "./WatchTheVideo";
import WhyWeDoIt from "./WhyWeDoIt";
import CallToAction from "../Home/CallToAction";
import Grid from "../Grid";

const ForTheMasses = () => {
  return (
    <>
      <Nav buttonText="Download" clickScroll />
      <Headline />
      <HowItWorks />
      <GameRules />
      <MeetBeasts />
      <WatchTheVideo />
      <WhyWeDoIt />
      <Grid />
      <CallToAction />
      <Footer />
    </>
  );
};

export default ForTheMasses;
