import React from "react";
import styles from "./howItWorks.module.scss";

const HowItWorks = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.container}></div>
      <div className={styles.container}>
        <img
          src="/beasts.png"
          alt="Hovering person on laptop"
          className={styles.image}
        />
      </div>
      <div className={styles.containerTwo}></div>
      <div className={styles.column}>
        <p className={styles.capitals}>HOW IT WORKS</p>
        <span className={styles.title}>
          Catch Beasts, Claim Territories, Win $30k.
        </span>
        <span className={styles.subtitle}>
          Create or join a Tribe and battle for dominance over a city with your
          friends by catching a Bitcoin Beast in all 21 Territories.
        </span>
      </div>
      <div className={styles.container}></div>
    </div>
  );
};

export default HowItWorks;
