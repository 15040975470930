import React, { useState } from "react";
import styles from "./callToAction.module.scss";
import { ParallaxLayer } from "@react-spring/parallax";
import { InView } from "react-intersection-observer";
import Modal from "react-modal";

const CallToAction = () => {
  const [inView, setInView] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "24px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    modalTitle: {
      fontSize: "32px",
      fontFamily: "Nunito",
    },
    closeButton: {
      fontSize: "32px",
      fontWeight: "bold",
      paddingBottom: "20px",
      cursor: "pointer",
    },
  };

  const openForm = async () => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "44829060",
          formId: "61146cdd-6f35-4b5f-aa73-1b6ec438627c",
          target: "#hubspotForm",
        });
      }
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
    openForm();
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  Modal.defaultStyles.overlay.backgroundColor = "#0636454F";

  return (
    <div className={styles.cover} id="download">
      <div className={styles.container}></div>
      <div className={styles.container}>
        <img
          src="/person_bubble.png"
          alt="Hovering person on laptop"
          className={styles.image}
        />
      </div>
      <div className={styles.containerTwo}></div>
      <div className={styles.column}>
        <p className={styles.capitals}>JOIN JIPPI</p>
        <span className={styles.title}>Download today.</span>
        <span className={styles.subtitle}>
          Join Jippi for free Bitcoin education and sats rewards. Tribe Clash is
          coming in early 2025.
        </span>
        <div className={styles.innerContainerTwo}>
          <img
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/jippi/id6471380135",
                "_blank"
              )
            }
            src="/app_store_badge.png"
            alt="person"
            className={styles.badge}
          />
          <img
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.jippiApp.jippi.prod",
                "_blank"
              )
            }
            src="/google_play_badge.png"
            alt="person"
            className={styles.badge}
          />
        </div>
      </div>
      <div className={styles.container}></div>
    </div>
  );
};

export default CallToAction;
