import React, { useState } from "react";
import styles from "./grid.module.scss";
import Modal from "react-modal";
import { Link } from "react-router-dom";

const Grid = () => {
  const openForm = async () => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "44829060",
          formId: "61146cdd-6f35-4b5f-aa73-1b6ec438627c",
          target: "#hubspotForm",
        });
      }
    });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
    openForm();
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  Modal.defaultStyles.overlay.backgroundColor = "#0636454F";

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "24px",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    modalTitle: {
      fontSize: "32px",
      fontFamily: "Nunito",
    },
    closeButton: {
      fontSize: "32px",
      fontWeight: "bold",
      paddingBottom: "20px",
      cursor: "pointer",
    },
  };

  return (
    <div className={styles.cover}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="modal"
      >
        <div style={customStyles.row}>
          <h2 style={customStyles.modalTitle}>Join the Newsletter</h2>
          <span style={customStyles.closeButton} onClick={closeModal}>
            &times;
          </span>
        </div>
        <div id="hubspotForm"></div>
      </Modal>
      <p className={styles.capitals}>MORE FROM JIPPI</p>
      <p className={styles.title}>Explore Our Offerings.</p>
      <div className={styles.gridContainer}>
        <div className={styles.gridItem}>
          <h3>Invest in Jippi</h3>
          <p>
            Jippi is offering a crowdraise through Timestamp for both the
            general public and angel investors. Invest in Jippi and receive
            equity.
          </p>
          <button
            onClick={() =>
              window.open(
                "https://invest.timestampfinancial.com/offering/jippi/details",
                "_blank"
              )
            }
          >
            Invest Now →
          </button>
        </div>
        <div className={styles.gridItem}>
          <h3>Join Newsletter</h3>
          <p>Stay up to date with Jippi news and updates.</p>
          <button onClick={() => openModal()}>Sign Up →</button>
        </div>
        <div className={styles.gridItem}>
          <h3>Bitcoin Beasts</h3>
          <p>Buy a surprise, limited edition Bitcoin Beast plushy. 1 of 21.</p>
          <button
            onClick={() =>
              window.open("https://pay.zaprite.com/pl_wZnBNNQ7wQ", "_blank")
            }
          >
            Buy →
          </button>
        </div>
        <div className={styles.gridItem}>
          <h3>Trading Cards</h3>
          <p>Buy a pack of Bitcoin Beast trading cards with all 21 designs.</p>
          <button
            onClick={() =>
              window.open("https://pay.zaprite.com/pl_uHP2yQxFag", "_blank")
            }
          >
            Buy →
          </button>
        </div>
        <div className={styles.gridItem}>
          <h3>Win $1,000 of BTC</h3>
          <p>
            Buy a Bitcoin Beast for a 1 in 21 chance to win $1,000 in Bitcoin.
          </p>
          <Link className={styles.link} to={`/bitcoin-beast-prize-draw`}>
            View →
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Grid;
