import React from "react";
import styles from "./beastPrizeDraw.module.scss";
import confetti from "canvas-confetti";

const BeastPrizeDraw = () => {
  var duration = 15 * 1000;
  var animationEnd = Date.now() + duration;
  var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  var interval = setInterval(function () {
    var timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    var particleCount = 50 * (timeLeft / duration);

    /// UNCOMMENT WHEN READY TO ANNOUNCE WINNER
    // confetti({
    //   ...defaults,
    //   particleCount,
    //   origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    // });
    // confetti({
    //   ...defaults,
    //   particleCount,
    //   origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    // });
  }, 250);

  // return (
  //   <div className={styles.cover}>
  //     <div className={styles.innerCover}></div>
  //     <h1>Congratulations!</h1>
  //     <div className={styles.wonContainer}>
  //       <img
  //         src="/bitcoin_beast.png"
  //         alt="Bitcoin Beast"
  //         className={styles.image}
  //       />
  //       <p>Bitcoin Beast A</p>
  //     </div>
  //     <p className={styles.text}>
  //       We will email
  //       <br />
  //       the winner.
  //     </p>
  //   </div>

  return (
    <div className={styles.cover}>
      <div className={styles.innerCover}></div>
      <h1>
        WIN $1,000
        <br />
        OF BITCOIN
      </h1>
      <div className={styles.container}>
        <p>Waiting for all</p>
        <div className={styles.ldsDualRing}></div>
        <p>Bitcoin Beasts to sell</p>
      </div>
      <p className={styles.text}>
        Stay up to date by following
        <br />
        @JippiApp on 𝕏
      </p>
    </div>
  );
};

export default BeastPrizeDraw;
