import React from "react";
import styles from "./gameRules.module.scss";

const GameRules = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.row}>
        <div className={styles.container}></div>
        <div className={styles.column}>
          <p className={styles.capitals}>GAME RULES</p>
          <span className={styles.title}>
            1 City. 21 Territories. 1 Winner.
          </span>
        </div>
        <div className={styles.container}></div>
        <div className={styles.container}></div>
      </div>
      <div className={styles.row}>
        <div className={styles.containerTwo}></div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img src="/hive.png" alt="One" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Complete Islands</strong>
          </p>
          <span className={styles.subtitle}>
            Gear up for Tribe Clash by completing Islands of educational courses
            on Bitcoin.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img src="/users.png" alt="Two" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Join a Tribe</strong>
          </p>
          <span className={styles.subtitle}>
            Battle for dominance over a city with your friends by joining a
            Tribe.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img src="/hexagon.png" alt="Three" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Claim Territories</strong>
          </p>
          <span className={styles.subtitle}>
            Jippi selects a random Territory in your city each week for your
            Tribe to claim.
          </span>
        </div>
        <div className={styles.outerIconContainer}>
          <div className={styles.iconContainer}>
            <img src="/catch.png" alt="Three" className={styles.iconTwo} />
          </div>
          <p className={styles.subtitle}>
            <strong>Catch Beasts</strong>
          </p>
          <span className={styles.subtitle}>
            Catch Bitcoin Beasts by answering quiz questions about Bitcoin.
          </span>
        </div>
        <div className={styles.containerTwo}></div>
      </div>
    </div>
  );
};

export default GameRules;
