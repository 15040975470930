import React, { useCallback } from "react";
import styles from "./meetBeasts.module.scss";
import useEmblaCarousel from "embla-carousel-react";

const Partnerships = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const beasts = [
    { name: "Nakamoto Nick", imageUrl: "/nakamoto_nick.png" },
    { name: "Blockchain Bob", imageUrl: "/blockchain_bob.png" },
    { name: "Sat Sarah", imageUrl: "/sats_sarah.png" },
    { name: "PoW Patrick", imageUrl: "/pow_patrick.png" },
    { name: "Hashing Henry", imageUrl: "/hashing_henry.png" },
    { name: "Mining Martin", imageUrl: "/mining_martin.png" },
    { name: "Private-key Paul", imageUrl: "/private_key_paul.png" },
    { name: "+14 More", imageUrl: "/more_beasts.png" },
  ];

  return (
    <div className={styles.cover}>
      <p className={styles.capitals}>MEET THE BEASTS</p>
      <p className={styles.title}>21 Unique Bitcoin-themed Beasts.</p>
      <div className={styles.embla} ref={emblaRef}>
        <div className={styles.embla__container}>
          {beasts.map((beast, index) => {
            return (
              <div className={styles.embla__slide}>
                <div className={styles.column}>
                  <img
                    src={beast.imageUrl}
                    alt="Bitcoin Beast"
                    className={index === 7 ? styles.unknownBeast : styles.beast}
                  />
                  <p>{beast.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.buttonRow}>
        <button className={styles.embla__button} onClick={scrollPrev}>
          <span>➜</span>
        </button>
        <button className={styles.embla__button} onClick={scrollNext}>
          <span>➜</span>
        </button>
      </div>
    </div>
  );
};

export default Partnerships;
